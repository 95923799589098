@import url('../../theming/colors.css');
@import url('../../theming/shadows.css');

.stationPageLayout {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.stationPageLayout .firstSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
}

.stationPageLayout .secondSection {
    width: 100%;
}

.vtDeployment {
    text-align: left;
}

.vtDeployment p {
    margin: 0;
}

.vtDeployment > * {
    margin-top: 10px;
}

.vtDeployment > *:first-child {
    margin-top: 0;
}

button.overviewTable  {
    background-color: transparent;
    height: 60px;
    border-radius: 10px;
    background-color: var(--vt-light-gray);
    position: relative;
    box-shadow: var(--basic-shadow);
}

button.overviewTable.danger:before  {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: absolute;
    top: -5px;
    left: 0;
    content: '!';
    background-color: var(--vt-danger);
}

button.overviewTable .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

button.overviewTable .content .arrow {
    width: 25px;
    height: 25px;
    margin-left: 10px
}

.colorCodes {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 0 10px 40px;
}

.colorCodes .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.colorCodes .item.hidden {
    opacity: 0.2;
}

.colorCodes .color {
    height: 25px;
    width: 25px;
    border-radius: 10px;
}

.colorCodes .name {
    margin-left: 10px;
    cursor: pointer;
}

.colorCodes .name:hover {
    color: var(--vt-light-gray);
}

.address {
    margin-top: 10px;
}

.address p {
    margin: 0;
}

.allAlerts {
    margin-top: 10px;
    padding: 0 10px;
    text-align: right;
}

.intermediateValuesLayout {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
        "table table"
        "cycle_on_time cycle_on_median"
        "cycle_off_time duty_cycle";
}

@media all and (max-width: 1400px) {
    .stationPageLayout {
        flex-direction: column;
    }
    .stationPageLayout .firstSection {
        max-width: 100%;
    }
    .intermediateValuesLayout {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: 
            "table"
            "cycle_on_time"
            "cycle_on_median"
            "cycle_off_time"
            "duty_cycle";
    }
}
