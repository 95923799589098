@import url('../../theming/colors.css');
@import url('../../theming/shadows.css');

.BreadcrumbsWrapper {
  height: 90px;
  overflow-x: auto;
  margin-bottom: 10px;
}

.Breadcrumbs {
  display: inline-flex;
  height: 60px;
  border-radius: 10px;
  box-shadow: var(--basic-shadow);
  background-color: var(--vt-bg);
  margin-right: 20px;
}

.Breadcrumbs .item {
  height: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.Breadcrumbs .item:nth-child(n + 2) {
  border-left: 1px solid white;
}

.Breadcrumbs .arrow {
  border-radius: 10px 0 0 10px;
  width: 60px;
}

.Breadcrumbs a.arrow:hover {
  background-color: var(--vt-green);
}

.Breadcrumbs .status {
  width: 60px;
  height: 100%;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0 10px 10px 0;
  font-size: 25px;
  font-weight: bold;
  cursor: default;
}
