.meter {
  position: relative;
  height: 100%;
}

.meter .value {
  position: absolute;
  top: 0; 
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
}

.meter .title {
  position: absolute;
  bottom: 0; 
  width: 100%;
  display: flex;
  justify-content: center;
}