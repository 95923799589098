@import url('../../theming/colors.css');
@import url('../../theming/shadows.css');
/* Fleet grid related styles. */

.FleetEntry button, .InstallationRow button.drilldown {
  border-radius: 0 10px 10px 0;
  width: 60px;
  height: 100%;
  flex-shrink: 0;
  border-left: 1px solid white;
}

.FleetEntry {
  margin: 10px;
  height: 60px;
  border-radius: 10px;
  box-shadow: var(--basic-shadow);
}

.FleetEntry.expanded   {
  --overlap: 25px; /* Bridge the gap to the detail box below.. */
  height: calc(60px + var(--overlap));
  margin-bottom: calc(0px - var(--overlap));
  background-color: var(--vt-bg);
  border-radius: 10px 10px 0 0;
}

.header {
  display: flex;
  align-items: center;

  border-radius: 10px;

  height: 60px;
  background-color: var(--vt-bg);
}

.FleetEntry .status, .InstallationRow .status {
  width: 60px;
  height: 100%;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px 0 0 10px;
  font-size: 25px;
  font-weight: bold;
  cursor: default;
}

.FleetEntry .name {
  margin: 0 10px;
  flex-grow: 1;
  word-break: break-word;
}

.FleetEntry button.dropdown {
  background-color: var(--vt-bg);
}

.FleetEntry .dropdown::after {
  background-image: url('../../icons/arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 35px;

  content: '';
  display: block;
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
}

.FleetEntry.expanded button.dropdown::after {
  transform: rotate(270deg);
}

.FleetEntry .dropdown:hover {
  background-color: var(--vt-green);
}

.FleetEntryDetails {
  display: flex;
  flex-direction: column;
  background-color: var(--vt-bg);
  min-height: 80px;
  margin: 0 10px;
  border-radius: 10px;
  padding: 10px;
}

.FleetEntryDetails .buttonsContainer {
  margin: 20px 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.FleetEntryDetails .buttonsContainer > * {
  margin-left: 20px;
}

.InstallationRow {
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: var(--vt-light-gray);

  margin-top: 10px;
}

.InstallationRow:first-child {
  margin-top: 0;
}

.InstallationRow .name {
  margin: 0 10px;
  flex-grow: 1;
}

.InstallationRow .equipmentStatus {
  display: flex;
  height: 100%;
  margin-left: auto;
}

.InstallationRow.noStatus .equipmentStatus  {
  margin-left: 0;
}

.InstallationRow .statusGrid {
  padding: 0 10px;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 40px 1fr 40px 1fr;
  grid-auto-rows: min-content;
}

.InstallationRow .waterLevelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 100%;
  border-left: 1px solid white;
}

.InstallationRow .phaseCurrentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 100%;
  border-left: 1px solid white;
}

.InstallationRow.noStatus .phaseCurrentContainer {
  border-left: 0;
}

.InstallationRow .phaseCurrentContainer .values {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 30px;
  justify-content: center;
}

.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 40px;
  height: 40px;
}

.icon.level {
  background-image: url('../../icons/water_drop.svg');
}

.icon.cycles {
  background-image: url('../../icons/cycle.svg');
}

.InstallationRow button.drilldown {
  background-color: var(--vt-light-gray);

  background-image: url('../../icons/arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 35px;
}

.InstallationRow.noStatus button.drilldown {
  margin-left: auto;
}

.InstallationRow button.drilldown:hover {
  background-color: var(--vt-green);
}

legend {
  color: var(--vt-gray);
  font-weight: bold;
}

.editFleet .buttonsContainer {
  display: flex;
  max-width: 500px;
  justify-content: space-between;
  margin-top: 20px;
}

@media all and (max-width: 550px) {
  .InstallationRow .phaseCurrentContainer {
    display: none;
  }
  .InstallationRow .waterLevelContainer {
    display: none;
  }
}