.legendsContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 10px;
  left: 40px;
}

.legendsContainer .item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-right: 15px;
}

@media all and (max-width: 500px) {
  .legendsContainer {
    flex-direction: column;
    bottom: -5px;
  }
}