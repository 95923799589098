.pumpPageLayout {  
  display: grid;
  gap: 20px;
  grid-auto-columns: 1fr; 
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 0.5fr 0.5fr; 
  grid-template-areas: 
    "overview overview status-meters status-meters status-meters"
    "overview overview status-plot status-plot status-plot"
    "status-notice status-notice operation operation operation";
}

.pumpPageLayout.noStatus {
  display: grid;
  gap: 20px;
  grid-auto-columns: 1fr; 
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 0.5fr 0.5fr; 
  grid-template-areas: 
    "overview operation operation operation operation"
    "overview operation operation operation operation"
    "status status status status status";
}

.conditionMeters {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
  min-width: 180px;
}

.statusMeters {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.statusNotice {
  margin-top: 34px;
}

.pumpPageLayout .overviewTable {
  min-width: 350px;
}

@media all and (max-width: 2000px) {
  .pumpPageLayout {
    grid-template-areas:
    "overview overview status-meters status-meters status-meters"
    "overview overview status-plot status-plot status-plot"
    "status-notice status-notice operation operation operation";
  }
  .pumpPageLayout.noStatus {
    grid-template-areas: 
      "overview operation operation operation operation"
      "overview operation operation operation operation"
      "status status status status status";
  }
}

@media all and (max-width: 1400px) {
  .pumpPageLayout {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "overview overview"
      "status-notice status-notice"
      "status-meters status-meters"
      "status-plot status-plot"
      "operation operation";
  }
  .pumpPageLayout.noStatus {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
      "overview overview"
      "operation operation"
      "status status";
  }
  .pumpPageLayout .conditionMeters {
    flex-direction: row;
  }
}

@media all and (max-width: 800px) {
  .pumpPageLayout {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "overview"
      "status-meters"
      "status-notice"
      "status-plot"
      "operation";
  }
  .pumpPageLayout.noStatus {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: 
      "overview"
      "operation"
      "status";
  }
  .statusNotice {
    margin-top: 0;
  }
  .pumpPageLayout .overviewTable {
    min-width: unset;
  }
}