button.vt {
  height: 60px;
  width: 100%;
  min-width: 120px;
  border-radius: 15px;
  background-color: var(--vt-bg);
  position: relative;
  box-shadow: var(--basic-shadow);
}

button.vt.marginTop {
  margin-top: 20px;
}

button.vt.minHeight {
  height: unset;
  min-height: 60px;
}

button.vt:hover .content .arrow {
  transform: translateX(5px);
}

button.vt.bgLight {
  background-color: var(--vt-light-gray);
}
button.vt.bgDanger {
  background-color: var(--vt-danger);
}
button.vt.bgWarning {
  background-color: var(--vt-warning);
}

button.vt.danger:before  {
  content: '!';
  width: 20px;
  height: 20px;
  border-radius: 5px;
  position: absolute;
  top: -5px;
  left: 0;
  background-color: var(--vt-danger);
}

button.vt .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

button.vt .content .text {
  width: 70%;
}

button.vt .content .text.green {
  color: var(--vt-green);
}

button.vt .content .arrow {
  width: 25px;
  height: 25px;
  transition: transform 200ms;
}