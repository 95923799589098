.sectionBoxContainer {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.sectionBox {
  flex-basis: 600px;
}

.sectionBox > h1 {
  margin: 0 0 20px 0;
}

.sectionBox > div {
  background-color: var(--vt-bg);
  box-shadow: var(--basic-shadow);
  padding: 20px;
  border-radius: 15px;
  min-height: 100px;
}
