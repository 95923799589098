.notification {
  border: 1px solid white;
  border-radius: 10px;
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
  font-weight: bold;
  padding: 20px;
  position: absolute;
  top: 10px;
  right: 0px;
  left: 0px;
  margin-right: auto;
  margin-left: auto;
  max-width: 300px;
  text-align: center;
  color: white;
  z-index: 99999;
}