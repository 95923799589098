@import url('../../theming/colors.css');
@import url('../../theming/shadows.css');
/* Fleet grid related styles. */

.deviceEntry button, .deviceInputDetails button.drilldown {
  border-radius: 0 10px 10px 0;

  width: 60px;
  height: 100%;
  flex-shrink: 0;
  border-left: 1px solid white;
}

.deviceEntry {
  margin: 10px;
  height: 60px;
  border-radius: 10px;
  box-shadow: var(--basic-shadow);
}

.deviceEntry.expanded   {
  --overlap: 25px; /* Bridge the gap to the detail box below.. */
  height: calc(60px + var(--overlap));
  margin-bottom: calc(0px - var(--overlap));
  background-color: var(--vt-bg);
  border-radius: 10px 10px 0 0;
}

.header {
  display: flex;
  align-items: center;

  border-radius: 10px;

  height: 60px;
  background-color: var(--vt-bg);
}

.deviceEntry .status, .deviceInputDetails .status {
  width: 60px;
  height: 100%;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 10px 0 0 10px;
  font-size: 25px;
  font-weight: bold;
  cursor: default;
}

.deviceEntry .name {
  margin: 0 10px;
  flex-grow: 1;
  word-break: break-word;
}

.deviceEntry button.dropdown {
  background-color: var(--vt-bg);
}

.deviceEntry .dropdown::after {
  background-image: url('../../icons/arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 35px;

  content: '';
  display: block;
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
}

.deviceEntry.expanded button.dropdown::after {
  transform: rotate(270deg);
}

.deviceEntry .dropdown:hover {
  background-color: var(--vt-green);
}

.deviceInputWrapper {
  background-color: var(--vt-bg);
  min-height: 150px;
  margin: 0 10px;
  border-radius: 10px;
  padding: 10px;
}

.deviceInputDetails {
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: var(--vt-light-gray);
  margin-top: 10px;
  overflow-x: auto;
}

.deviceInputDetails > div:nth-last-child(2) {
  border-left: 1px solid white;
}

.deviceInputDetails .name {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 10px;
  height: 100%;
}

.deviceInputDetails button.drilldown {
  background-color: var(--vt-light-gray);

  background-image: url('../../icons/arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 35px;
}

.deviceInputDetails button.drilldown:hover {
  background-color: var(--vt-green);
}

legend {
  color: var(--vt-gray);
  font-weight: bold;
}

.devices button.navigate {
  background-color: var(--vt-bg);
  background-image: url('../../icons/arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 35px;
  border-radius: 0 10px 10px 0;
  border-left: 1px solid white;
  width: 50px;
  flex-shrink: 0;
}

.devices button.navigate:hover {
  background-color: var(--vt-green);
}

.deviceInputsTable .deviceInputsTableRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.deviceInputsTable .deviceInputsTableRow .timeSince {
  position: absolute;
  top: 29px;
  font-size: 11px;
}

@media all and (max-width: 400px) {
  .deviceInputsTable .deviceInputsTableRow .timeSince {
    position: unset;
  }
}