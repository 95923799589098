@import url('../../theming/colors.css');
@import url('../../theming/shadows.css');

.container {
  display: inline-block;
  position: relative;
}

.closeButton {
  display: none;
  background-color: transparent;
  position: absolute;
  font-size: 25px;
  top: 0;
  right: 0;
  z-index: 99999;
  width: 30px;
  height: 30px;
}

.Button {
  display: inline-flex;
  position: relative;
  z-index: 11; /* higher than content to not get shadowed */
  align-items: center;
  padding: 0;
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--vt-fg);;
  background-color: var(--vt-bg);
  color: var(--vt-fg);
  z-index: 9;
}

.Button:hover{
  background-color: var(--vt-light-gray);
}

.Button.open {
  background-color: var(--vt-light-gray);
  border-bottom-color: var(--vt-more-light-gray);
  border-radius: 10px 10px 0 0;
}

.main {
  flex-grow: 1;
  padding: 0 1em;
}

.arrow {
  border-left: 1px solid white;
  width: 40px;
  padding: 10px;
  height: 100%;
  text-align: center;
}

.dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 9999;
}

.InputGroup {
  /* a bit like boostrap input-group */
  display: flex;
  align-items: center;

  border-radius: 10px;
  background-color: var(--vt-gray);
  
  color: var(--vt-fg);
  margin: 0;
  height: 40px;

  margin-bottom: 15px;
}

.InputGroup > button {
  border: 0;
}
.InputGroup > button:hover {
  background-color: var(--vt-light-gray);
}

.InputGroup > *:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: 1px solid var(--vt-fg);
  border-bottom: 1px solid var(--vt-fg);
  border-left: 1px solid var(--vt-fg);
}

.InputGroup > *:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid var(--vt-fg);
  border-bottom: 1px solid var(--vt-fg);
  border-right: 1px solid var(--vt-fg);
}

.InputGroup > div, .InputGroup > button {
  display: flex;
  align-items: center;
  padding: 0 5px;
  background-color: var(--vt-bg);
  height: 100%;
}

.InputGroup > input {
  width: 10px;
  flex-grow: 1;
  height: 100%;

  position: relative;
  z-index: 1;
}

.TextInput, .SubmitInput, .select {
  height: 40px;
  border: 1px solid var(--vt-fg);
  border-radius: 10px;
  padding: 0 0.5em;
  background-color: var(--vt-bg);
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  width: 100%;
}

.TextInput::placeholder {
  color: white;
  opacity: 0.3;
}

.SubmitInput {
  border: none;
  background-color: var(--vt-gray);
  box-shadow: var(--basic-shadow);
}

.SubmitInput:hover, .SubmitInput:focus {
  background-color: var(--vt-green);
}

.selectWithLabel, .inputWithLabel {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  gap: 3px;
}

.selectWithLabel label, .inputWithLabel label {
  font-weight: bold;
}

.inputWithLabel textarea {
  resize: none;
  padding: 0.5em;
  height: 100%; 
}

.selectWithLabel .infoWrapper, .inputWithLabel .infoWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.inputWithLabel .infoWrapper .infoLabel, .inputWithLabel .infoWrapper .infoLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputWithLabel input:focus {
  outline: none; 
  -webkit-box-shadow: inset 0px 0px 7px  black;
  box-shadow: inset 0px 0px 7px  black;
}

textarea::-webkit-scrollbar {
  width: 10px;
}

textarea::-webkit-scrollbar-track {
  border-radius: 10px;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5); 
}

.checkbox {
  cursor: pointer;
  width: 25px;
}

@media all and (max-width: 650px) {
  .container {
    position: unset;
  }
  .dropdown {
    width: 100%;
    top: 0;
    z-index: 9999;
  }
  .closeButton {
    display: block;
  }
}
