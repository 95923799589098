@import url('../../theming/colors.css');

.table {
  border-collapse: collapse;
  width: 100%;
}

.table td {
  padding: 15px;
  text-align: center;
}

.table td {
  border: 1px solid white;
}

.table td:first-child {
  text-align: right;
  font-weight: bold;

  border-left: none;
}

.table td:last-child {
  border-right: none;
}

.table tr:first-child td {
  border-top: none;
}

.table tr:nth-child(2) td {
  border-top: none;
}

.table.noFirstBorder tr:first-child td {
  border: none;
}

.table.noFirstBorder tr:nth-child(2) td {
  border-top: none;
}

.table tr:last-child td {
  border-bottom: none;
}

.table td.noData {
  text-align: center;
  font-weight: normal;
}

button.table  {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  
  height: 60px;
  border-radius: 10px;
  background-color: var(--vt-light-gray);
  position: relative;
  box-shadow: var(--basic-shadow);
}

button.table.danger:before  {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  position: absolute;
  top: -5px;
  left: 0;
  content: '!';
  background-color: var(--vt-danger);
}

button.table .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

button.table .content .arrow {
  width: 25px;
  height: 25px;
  margin-left: 10px
}

.pagination {
  display: inline-flex;
  height: 80px;
  margin: 20px 0 0 5px;
  overflow-x: auto;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
}

.pagination button {
  height: 60px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--vt-bg);
  width: 50px;
  box-shadow: var(--basic-shadow);

  text-decoration: none;
  color: inherit;
}

.pagination button:disabled, .pagination button:disabled:hover {
  cursor: default;
  background-color: var(--vt-light-gray);
}

.pagination button:nth-child(n + 2) {
  border-left: 1px solid white;
}

.pagination button:first-child {
  border-radius: 10px 0 0 10px;
}

.pagination button:last-child {
  border-radius: 0 10px 10px 0;
}

.pagination .arrow {
  width: 60px;
}

.pagination button:hover, .pagination button.active {
  background-color: var(--vt-green);
}

.pagination button.separator:hover {
  cursor: default;
  background-color: var(--vt-bg);
}

.tableConfig {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.tableConfig fieldset {
  margin: 0;
  padding: 5px 10px 0 5px;
  border: 0;
}

.tableConfig .entryCount {
  margin-left: auto;
  margin-top: auto;
}
