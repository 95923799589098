@import url('../../theming/colors.css');

.newAlert .buttonsContainer {
  display: flex;
  max-width: 500px;
  justify-content: space-between;
  margin-top: 20px;
}

.newAlert .deviceInputsContainer {
  max-width: 500px;
  margin-bottom: 20px;
}

/* alert list / table definition */
.alertList {
  display: grid;
  grid-template-columns: [status-start] 50px [details-start] 2fr [startTime-start] 1fr [endTime-start] 1fr [duration-start] 1fr [nav-start] 40px [nav-end];
  row-gap: 10px;
}

.header, .alert {
  display: contents;
}

.header > div {
  text-align: center;
}

.alert > div {
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.alert > div + div {
  background-color: var(--vt-light-gray);
  padding: 5px 10px;
  border-right: 1px solid white;
}


.alert > div:first-child {
  border-radius: 10px 0 0 10px;
}

.alert > div:last-child {
  border-radius: 0 10px 10px 0;
  border-right: 0;
}

.status {
  grid-column: status-start;
  font-weight: bold;
}

.details {
  grid-column: details-start;
}

.alert > .details {
  justify-content: start;
  text-align: left;
}

.startTime {
  grid-column: startTime-start;
}

.endTime {
  grid-column: endTime-start;
}

.duration {
  grid-column: duration-start;
}

.alert > .nav {
  grid-column: nav-start;
  padding: 0;
}

.nav > a {
  border-radius: 0 10px 10px 0;
  display: block;
  height: 100%;
  width: 100%;
}

.nav > a:hover {
  background-color: var(--vt-green);
}
