@import url('../../theming/colors.css');

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: var(--vt-gray);
  border: 1px solid #a0a096;
  line-height: 1.125em;
}
.react-calendar abbr {
  text-decoration: none;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: var(--vt-bg);
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--vt-green);
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--vt-more-light-gray);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  position: relative;
  border-radius: 10px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--vt-accent2);
}
.react-calendar__tile--now::after {
  content: '';
  display: block;
  width: calc(100% - 20px);
  height: calc(100% - 6px);
  position: absolute;
  left: 10px;
  top: 3px;
  border: 2px solid var(--vt-accent1);
  border-radius: 50%;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: var(--vt-accent3);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar__tile--hover {
  border-radius: 0;
}
.react-calendar__tile--hoverStart {
  border-radius: 10px 0 0 10px;
}
.react-calendar__tile--hoverEnd {
  border-radius: 0 10px 10px 0;
}
.react-calendar__tile--hoverBothEnds {
  border-radius: 10px;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--vt-accent2);
}
