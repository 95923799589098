.wizard .buttonsContainer {
  display: flex;
  max-width: 500px;
  justify-content: space-between;
  margin-top: 20px;
}

.wizard .formContainer {
  max-width: 500px;
}

.wizard form {
  margin: 20px;
}

.wizard .machinesContainer {
  max-width: 1200px;
}

.wizard .machinesContainer .buttonsContainer {
  margin: 20px 0 0 auto;
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wizard .machineFormContainer {
  margin: 20px;
  position: relative;
}

.wizard .machineFormContainer .removeButtonWrapper {
  position: absolute;
  right: -20px;
  top: -20px;
}

.wizard .machineFormContainer .machineInformationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
}

.wizard .machineFormContainer .machineInformationContainer .pump {
  width: 500px;
}

.wizard .machineFormContainer .machineInformationContainer .motor {
  width: 500px;
}

.wizard .machineFormContainer .measurementScaling {
  max-width: 500px;
}

.wizard .checkboxContainer {
  display: flex;
  flex-direction: row;
}

.wizard .checkboxContainer h2 {
  margin: 5px 0;
}

.wizard .equipmentFormContainer {
  width: 500px;
}

.wizard .surfaceContainer {
  width: 500px;
}

.wizard .equipmentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
}

.wizard .deviceInputsContainer {
  width: 650px;
  margin-left: 34px;
}

@media all and (max-width: 800px) {
  .wizard .equipmentFormContainer, .wizard .surfaceContainer {
    width: 100%;
  }

  .wizard .deviceInputsContainer {
    width: 100%;
    margin: 34px 0 0 0;
  }

  .wizard .equipmentContainer {
    flex-direction: column;
  }
}

@media all and (max-width: 600px) {
  .wizard .machineFormContainer .machineInformationContainer {
    flex-direction: column;
  }

  .wizard .machineFormContainer .machineInformationContainer .pump {
    width: 100%;
  }
  
  .wizard .machineFormContainer .machineInformationContainer .motor {
    width: 100%;
  }
}
