@import url('./theming/colors.css');

body {
  margin: 0;
  background-color: var(--vt-light-gray);
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

button, input {
  color: inherit;
  font: inherit;
  font-weight: normal;
}
