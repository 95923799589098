.markerWrapper {
  display: flex;
  flex-direction: row;
}

.markerWrapper .status {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0 0 10px;
  font-size: 25px;
  font-weight: bold;
}

.markerWrapper .textWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.markerWrapper .textWrapper > div {
  height: 100%;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 10px;
  border-right: 1px solid white;
}

.markerWrapper .textWrapper > div:last-child {
  border-right: none;
}

.markerWrapper button.navigate {
  background-color: var(--vt-bg);
  background-image: url('../../icons/arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 35px;
  border-radius: 0 10px 10px 0;
  border-left: 1px solid white;
  width: 50px;
  flex-shrink: 0;
}

.markerWrapper button.navigate:hover {
  background-color: var(--vt-green);
}

.map label {
  font-weight: bold;
}

.map .coordinates {
  margin-bottom: 5px;
}

button.locate, button.reset {
  background-color: white;
  height: 40px;
  padding: 0 10px;
  color: black;
  font-weight: bold;
  font-size: 14px;
  width: 100px;
}

button.locate:hover {
  background-color: var(--vt-green);
}

button.reset:hover {
  background-color: var(--vt-danger);
}