@import url('../../theming/colors.css');

.ChoiceWidget {
    display: inline-flex;
    height: 40px;
    border-radius: 10px;
    border: 1px solid white;
    user-select: none;
  }
  
.ChoiceWidget input {
  display: none;
}

.ChoiceWidget label {
  flex-grow: 1;

  cursor: pointer;

  padding: 0 10px;
  min-width: 80px;
  line-height: 38px;
  text-align: center;

  border-left: 1px solid white;
  overflow: clip; /* for animation */
  
  position: relative;
  z-index: 1;

  height: 100%;
}

.ChoiceWidget label:first-child {
  border-radius: 10px 0 0 10px;
  border-left:0;
}

.ChoiceWidget label:last-child {
  border-radius: 0 10px 10px 0;
}


.selected::after {
  content: '';
  display: block;
  width: calc(100% + 2em); /* account for padding in label.. */
  height: 100%;
  background-color: var(--vt-more-light-gray);
  position: relative;
  left: -1em; /* Re: padding in label. */
  z-index: -1;
  top: -100%;
}

.changed::after {
  animation: slidein 0.4s;
}

@keyframes slidein {
  0% {
    background-color: var(--vt-green);
    transform: translateX(-50%) skew(-30deg, 0deg) scaleX(0.1) scaleY(2);
  }

  50% {
    
    transform: translateX(+10%) skew(-30deg, 0deg) scaleX(0.05) scaleY(2);
  }

  75% {
    background-color: var(--vt-green);
    transform: translateX(0) scaleY(2);
  }

  100% {
    background-color: var(--vt-more-light-gray);
  }
}