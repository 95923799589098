:root {
  --vt-fg: white;
  --vt-bg: #6a6a69;

  --vt-gray: #575756;
  --vt-green: #93C152;

  /* secondary colors */
  --vt-accent1: #EFB600;
  --vt-accent2: #0093E5;
  --vt-accent3: #0033FF;

  --vt-danger: #E52000;
  --vt-warning: #EFB600;

  /* undefined colors? */
  --vt-light-gray: #878787; 
  --vt-more-light-gray: #a5a5a4;
}
