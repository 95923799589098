.leaflet-popup-content-wrapper {
  border-radius: 10px;
}

.leaflet-popup-content-wrapper {
  padding: 0;
  height: 50px;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background-color: var(--vt-bg);
  color: white;
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-control .info {
  color: black;
  font-size: 14px;
  font-weight: bold; 
}

.leaflet-coordinates-container {
  color: #fff;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 22px;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 461;
  pointer-events: none;
}

.leaflet-container:after {
  background: rgba(0, 0, 0, 0.9);
}

.leaflet-marker-icon.danger {
  z-index: 999 !important;
}